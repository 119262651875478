import { constants } from '@/utils/constants';
import { config } from '@/utils/config';
import { RouteConfig } from 'vue-router';

// routes
// ├── * (not found)
// └── layoutRoutes
//     ├──publicRoutes (FullPage.vue)
//     ├──publicRoutesWIP (FullPage.vue)
//     ├──privateRoutesWIP (Main.vue)
//     └──privateRoutes (Main.vue)
//        ├──companySubRoutes
//        ├──productsSubRoutes
//        └── ... (other sub routes for nested router views)
//
// P.S. WIP - routes, which are either:
// - not supposed to be in the next production release
// - require some updates to their RouteConfig

// =============================================================================
// PRIVATE(auth required) ROUTES FOR Main LAYOUT
// =============================================================================

const companySubRoutes: RouteConfig[] = [
  {
    path: '',
    alias: 'about',
    name: constants.routes.COMPANY,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyAbout.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Company', url: '/company' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.COMPANY,
      browserTitle: constants.titles.COMPANY_ABOUT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'contacts',
    name: constants.routes.CONTACTS,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyContacts.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Company', url: '/company' },
        { i18n: 'Link.Contacts', url: '/company/contacts' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.COMPANY,
      browserTitle: constants.titles.CONTACTS,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
];

const productsSubRoutes: RouteConfig[] = [
  {
    path: '',
    name: constants.routes.PRODUCT_LIST,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/ProductListingSummary.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Products', url: '/products' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCTS,
      browserTitle: constants.titles.PRODUCTS,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'options',
    name: constants.routes.OPTION_LIST,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/OptionListingSummary.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Options', url: '/products/options' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCTS,
      browserTitle: constants.titles.OPTIONS,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'traveler-types',
    name: constants.routes.TRAVELER_LIST,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/CompanyTravelerTypes.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TravelerTypes', url: '/products/travelers' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCTS,
      browserTitle: constants.titles.TRAVELER_TYPES,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
];

const inventorySubRoutes: RouteConfig[] = [
  {
    path: '',
    alias: 'offer',
    name: constants.routes.OFFER_LIST,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/offer/OfferListingSummary.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/inventory' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OFFER,
      browserTitle: constants.titles.OFFER,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'inventory',
    name: constants.routes.INVENTORY_MANAGEMENT,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/InventoryListSummary.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Availability', url: '/inventory/availability' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.INVENTORY,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'taxes-and-fees',
    name: constants.routes.TAXES_AND_FEES,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/TaxesAndFeesListing.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TaxesAndFees', url: '/inventory/taxes-and-fees' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.TAXES_AND_FEES,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'availability-status',
    name: constants.routes.AVAILABILITY_STATUS,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/AvailabilityStatus.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.AvailabilityStatus', url: '/inventory/availability-status' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.AVAILABILITY_STATUS,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
];

const channelSubRoutes: RouteConfig[] = [
  {
    path: '',
    name: constants.routes.CHANNEL_LIST,
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/ChannelListing.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNELS,
      browserTitle: constants.titles.CONNECTED_CHANNELS,
    },
  },
  {
    path: 'available-channels',
    name: constants.routes.CHANNEL_AVAILABLE_LIST,
    component: () =>
      import(
        /* webpackChunkName: "channel" */ '@/views/channels/AvailableChannelListing.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels/available' },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNELS,
      browserTitle: constants.titles.AVAILABLE_CHANNELS,
    },
  },
  {
    path: 'rate-cards',
    name: constants.routes.CHANNEL_RATE_CARDS,
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/RateCardListings.vue'),
    meta: {
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNELS,
      browserTitle: constants.titles.RATE_CARDS,
    },
  },
];

const privateRoutesWIP: RouteConfig[] = [
  {
    path: 'features',
    name: 'admin-feature-flag',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/FeatureFlag.vue'),
    meta: {
      role: [
        constants.roles.RC_CONNECTIVITY_MANAGEMENT,
        constants.roles.REDEAM_TECHNICAL_STAFF,
      ],
    },
  },
  {
    path: 'reports',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/ReportHome.vue'),
    meta: {
      feature: constants.features.ROUTING_REPORT_MAIN,
      title: constants.titles.REPORTS,
    },
    children: [
      {
        path: 'reports/manifest',
        name: 'report-customer-manifest-child',
        component: () =>
          import(
            /* webpackChunkName: "reports" */ '@/views/reports/CustomerManifestReport.vue'
          ),
        meta: {
          feature: constants.features.ROUTING_REPORT_MAIN,
          role: [constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF],
          title: constants.titles.CUSTOMER_MANIFEST,
        },
      },
      {
        path: 'reports/all-bookings',
        name: 'report-all-bookings-child',
        component: () =>
          import(
            /* webpackChunkName: "reports" */ '@/views/reports/AllBookingsReport.vue'
          ),
        meta: {
          feature: constants.features.ROUTING_REPORT_MAIN,
          role: [constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF],
          title: constants.titles.ALL_BOOKINGS,
        },
      },
    ],
  },
  {
    path: 'reports/daily',
    name: 'report-daily-summary',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/ReportHome.vue'),
    meta: {
      feature: constants.features.ROUTING_REPORT_DAILY_OP,
      role: [constants.roles.SUPPLIER_REPORTING_STAFF],
      title: constants.titles.REPORTS,
    },
  },
  {
    path: 'reports/weekly',
    name: 'report-weekly-summary',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/ReportHome.vue'),
    meta: {
      feature: constants.features.ROUTING_REPORT_WEEKLY_OP,
      role: [constants.roles.SUPPLIER_REPORTING_STAFF],
      title: constants.titles.REPORTS,
    },
  },
];

const privateRoutes: RouteConfig[] = [
  {
    path: 'locations',
    name: constants.routes.LOCATIONS,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyLocations.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Location', url: '/locations' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
      title: constants.titles.LOCATIONS,
    },
  },
  {
    path: 'locations/add',
    name: constants.routes.LOCATION_ADD,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyLocationEdit.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Location', url: '/locations' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.LOCATION_ADD,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
    },
  },
  {
    path: 'locations/edit/:id',
    name: constants.routes.LOCATION_EDIT,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyLocationEdit.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Location', url: '/locations' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.LOCATION_EDIT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
    },
  },
  {
    path: 'locations/view',
    name: constants.routes.LOCATION_VIEW,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyLocationView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Location', url: '/locations' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.LOCATION_VIEW,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'redemption',
    name: constants.routes.REDEMPTION,
    component: () =>
      import(/* webpackChunkName: "redemption" */ '@/views/main/WebRedemption.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Redemption', url: '/redemption' },
      ],
      feature: constants.features.ROUTING_WEB_REDEMPTION,
      role: [constants.roles.SUPPLIER_REDEMPTION_STAFF],
      title: constants.titles.REDEMPTION,
    },
  },
  {
    path: 'profile',
    name: constants.routes.PROFILE,
    component: () =>
      import(/* webpackChunkName: "main" */ '@/views/main/UserProfile.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Profile', url: '/profile' },
      ],
      title: constants.titles.SETTINGS,
    },
  },

  /* USERS */

  {
    path: 'users',
    name: constants.routes.USERS,
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/UserManagement.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Users', url: '/users' },
      ],
      feature: constants.features.ROUTING_USER_MAIN,
      title: constants.titles.USERS,
    },
  },
  {
    path: 'users/:id',
    name: constants.routes.USER_DETAILS,
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/UserDetails.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Users', url: '/users' },
        { i18n: 'Link.Details', url: '/users/:id', param: true },
      ],
      feature: constants.features.ROUTING_USER_MAIN,
      role: [constants.roles.ORG_MGMT],
      title: constants.titles.USER,
    },
    props: true,
  },
  {
    path: 'users/view/:id',
    name: constants.routes.USER_DETAILS_VIEW,
    component: () =>
      import(/* webpackChunkName: "users" */ '@/views/users/UserDetailView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Users', url: '/users' },
        { i18n: 'Link.ViewDetails', url: '/users/view/:id', param: true },
      ],
      feature: constants.features.ROUTING_USER_MAIN,
      role: [constants.roles.ORG_MGMT],
      title: constants.titles.USER,
    },
    props: true,
  },

  /* COMPANY */

  {
    path: '',
    name: constants.routes.BASE,
    component: () => import(/* webpackChunkName: "Base" */ '@/views/main/Home.vue'),
    meta: {
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.HOME,
    },
  },
  {
    path: 'home',
    name: constants.routes.HOME,
    redirect: { name: constants.routes.BASE },
  },
  {
    path: 'company',
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/Company.vue'),
    meta: {
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.COMPANY,
    },
    children: companySubRoutes,
  },
  {
    path: '/company/edit',
    alias: 'company-edit',
    name: constants.routes.COMPANY_EDIT,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyEdit.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Company', url: '/company' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
      title: constants.titles.COMPANY,
    },
  },
  // {
  //   path: 'home',
  //   name: constants.routes.HOME,
  //   redirect: { name: constants.routes.COMPANY },
  // },
  {
    path: '/company/contacts/add',
    alias: 'contacts-add',
    name: constants.routes.CONTACTS_ADD,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/company/CompanyContactsAdd.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Company', url: '/company' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.CONTACTS_ADD,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
    },
  },
  {
    path: '/company/contacts/edit/:id',
    alias: 'contacts-edit',
    name: constants.routes.CONTACTS_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/company/CompanyContactsAdd.vue'
      ),
    props: true,
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Contacts', url: '/contacts' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.CONTACTS_EDIT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
    },
  },
  {
    path: '/company/contacts/view/:id',
    alias: 'contacts-view',
    name: constants.routes.CONTACTS_VIEW,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/company/CompanyContactView.vue'
      ),
    props: true,
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Contacts', url: '/contacts' },
      ],
      feature: constants.features.ROUTING_COMPANY_MAIN,
      title: constants.titles.CONTACTS,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },

  /* PRODUCTS */

  {
    path: 'products',
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/ProductListing.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCTS,
    },
    children: productsSubRoutes,
  },
  {
    path: 'products/view/:id',
    name: constants.routes.PRODUCT_DETAILS,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/ProductView.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'products/create',
    name: constants.routes.PRODUCT_CREATE,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/ProductCreate.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCT_CREATE,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'capacity/create',
    name: constants.routes.PRODUCT_TIME_CAPACITY_ADD,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/detail/ProductTimeCapacityCreate.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Products', url: '/products' },
        { i18n: 'Link.Products', url: '/products/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCT_TIME_CAPACITY_ADD,
      browserTitle: constants.titles.PRODUCT_TIME_CAPACITY_ADD,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'capacity/edit',
    name: constants.routes.PRODUCT_TIME_CAPACITY_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/detail/ProductTimeCapacityEdit.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Products', url: '/products' },
        { i18n: 'Link.Products', url: '/products/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRODUCT_TIME_CAPACITY_EDIT,
      browserTitle: constants.titles.PRODUCT_TIME_CAPACITY_EDIT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'products/option/create',
    name: constants.routes.OPTION_CREATE,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/OptionCreate.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OPTION_CREATE,
      browserTitle: constants.titles.OPTION_CREATE,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'products/option/:id',
    name: constants.routes.OPTION_DETAILS,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/OptionView.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OPTION,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'products/traveler-types/create',
    name: constants.routes.TRAVELER_CREATE,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/TravelerTypeCreate.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TravelerTypes', url: '/products/travelers' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.TRAVELER_TYPE_CREATE,
      browserTitle: constants.titles.TRAVELER_TYPES,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'products/traveler-types/edit/:id',
    name: constants.routes.TRAVELER_EDIT,
    component: () =>
      import(/* webpackChunkName: "product" */ '@/views/product/TravelerTypeView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TravelerTypes', url: '/products/travelers' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.TRAVELER_TYPE_VIEW,
      browserTitle: constants.titles.TRAVELER_TYPES,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'capacity/option/create',
    name: constants.routes.OPTION_TIME_CAPACITY_ADD,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/detail/OptionTimeCapacityCreate.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Products', url: '/options' },
        { i18n: 'Link.Products', url: '/options/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OPTION_TIME_CAPACITY_ADD,
      browserTitle: constants.titles.OPTION_TIME_CAPACITY_ADD,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'capacity/option/edit',
    name: constants.routes.OPTION_TIME_CAPACITY_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "product" */ '@/views/product/detail/ProductTimeCapacityEdit.vue'
      ),
    props: {
      option: true,
    },
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Products', url: '/options' },
        { i18n: 'Link.Products', url: '/options/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OPTION_TIME_CAPACITY_EDIT,
      browserTitle: constants.titles.OPTION_TIME_CAPACITY_EDIT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  /*FEEDBACK*/
  {
    path: 'feedback',
    name: constants.routes.FEEDBACK,
    component: () =>
      import(/* webpackChunkName: "feedback" */ '@/views/feedback/Feedback.vue'),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.FEEDBACK,
    },
  },
  /* INVENTORY: PRICING, TAXES AND FEES, INVENTORY & AVAILABILITY */

  {
    path: 'prices',
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/InventoryListing.vue'
      ),
    meta: {
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.INVENTORY,
    },
    children: inventorySubRoutes,
  },
  // pricing
  {
    path: 'prices/pricing/view/:id',
    name: constants.routes.PRICING_VIEW,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/inventory/PricingView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/prices/pricing' },
        { i18n: 'Link.PricingDetail', url: '/prices/pricing/view/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_VIEW,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/pricing/edit/:id',
    name: constants.routes.PRICING_EDIT,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/inventory/PricingEdit.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/prices/pricing' },
        { i18n: 'Link.PricingDetail', url: '/prices/pricing/:id', param: true },
        { i18n: 'Link.PricingEdit', url: '/prices/pricing/:id/edit', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_EDIT,
    },
  },
  {
    path: 'prices/pricing/create',
    name: constants.routes.PRICING_CREATE,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/inventory/PricingCreate.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/prices/pricing' },
        { i18n: 'Link.PricingCreate', url: '/prices/pricing/create' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      title: constants.titles.PRICING,
      browserTitle: constants.titles.PRICING_CREATE,
    },
  },
  // offer
  {
    path: 'prices/offer/view/:id',
    name: constants.routes.OFFER_VIEW,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/offer/ProductOfferView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/offer' },
        { i18n: 'Link.PricingDetail', url: '/offer/view/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OFFER_VIEW,
      browserTitle: constants.titles.OFFER,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/offer/edit/:id',
    name: constants.routes.OFFER_EDIT,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/offer/ProductOfferView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/offer' },
        { i18n: 'Link.PricingDetail', url: '/offer/edit/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OFFER_EDIT,
      browserTitle: constants.titles.OFFER,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/offer/create',
    name: constants.routes.OFFER_CREATE,
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/offer/ProductOfferCreate.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Pricing', url: '/offer' },
        { i18n: 'Link.PricingDetail', url: '/offer/create/:id', param: true },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.OFFER_CREATE,
      browserTitle: constants.titles.OFFER,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  // taxes and fees
  {
    path: 'prices/taxes-and-fees/view/:id',
    name: constants.routes.TAXES_AND_FEES_VIEW,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/TaxesAndFeesView.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TaxesAndFees', url: '/prices/taxes-and-fees' },
        {
          i18n: 'Link.TaxesAndFeesDetails',
          url: '/prices/taxes-and-fees/view/:id',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.TAXES_AND_FEES,
      browserTitle: constants.titles.TAXES_AND_FEES,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/taxes-and-fees/create',
    name: constants.routes.TAXES_AND_FEES_CREATE,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/TaxesAndFeesCreate.vue'
      ),
    props: true,
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TaxesAndFees', url: '/prices/taxes-and-fees' },
        {
          i18n: 'Link.TaxesAndFeesDetails',
          url: '/prices/taxes-and-fees/create',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.TAXES_AND_FEES_ADD,
      browserTitle: constants.titles.TAXES_AND_FEES_ADD,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/taxes-and-fees/edit/:id',
    name: constants.routes.TAXES_AND_FEES_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/TaxesAndFeesCreate.vue'
      ),
    props: true,
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.TaxesAndFees', url: '/prices/taxes-and-fees' },
        {
          i18n: 'Link.TaxesAndFeesDetails',
          url: '/prices/taxes-and-fees/edit/:id',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.TAXES_AND_FEES_EDIT,
      browserTitle: constants.titles.TAXES_AND_FEES_EDIT,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  // availability
  {
    path: 'prices/inventory/view/:id',
    name: constants.routes.AVAILABILITY_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/AvailabilityHome.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Availability', url: '/prices/inventory' },
        {
          i18n: 'Link.AvailabilityDetail',
          url: '/prices/inventory/:id',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      title: constants.titles.AVAILABILITY,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT],
    },
  },
  {
    path: 'prices/inventory/edit/:id',
    name: constants.routes.AVAILABILITY_EDIT,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/AvailabilityEdit.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Availability', url: '/prices/inventory' },
        {
          i18n: 'Link.AvailabilityDetail',
          url: '/prices/inventory/:id',
          param: true,
        },
        {
          i18n: 'Link.AvailabilityEdit',
          url: '/prices/inventory/:id/edit',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      title: constants.titles.AVAILABILITY,
    },
  },
  {
    path: 'prices/inventory/create',
    name: constants.routes.AVAILABILITY_CREATE,
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/inventory/AvailabilityCreate.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Availability', url: '/prices/inventory' },
        { i18n: 'Link.AvailabilityCreate', url: '/prices/inventory/create' },
      ],
      feature: constants.features.ROUTING_PRODUCT_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      title: constants.titles.ADD_INVENTORY,
    },
  },

  /* CHANNELS */

  {
    path: 'channels',
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/Channels.vue'),
    meta: {
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      title: constants.titles.CHANNELS,
    },
    children: channelSubRoutes,
  },
  {
    path: 'channels/details/:id',
    name: constants.routes.CHANNEL_DETAILS,
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/ChannelDetails.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
        { i18n: 'Link.ChannelDetails', url: '/channels/details/:id', param: true },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      title: constants.titles.CONNECTED_CHANNEL,
      browserTitle: constants.titles.CONNECTED_CHANNEL,
      role: [
        constants.roles.RC_CONNECTIVITY_MANAGEMENT,
        // constants.roles.REDEAM_CENTRAL_TICKETING_VIEW,
        // constants.roles.REDEAM_CENTRAL_TICKETING_EDIT,
      ],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
    },
  },
  {
    path: 'channels/:id/contract/:contract/rate/:rate',
    name: constants.routes.CHANNEL_CONTRACT_RATE_DETAILS,
    component: () =>
      import(
        /* webpackChunkName: "channel" */ '@/views/channels/ChannelRateCardDetails.vue'
      ),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
        { i18n: 'Link.ChannelDetails', url: '/channels/details/:id', param: true },
        {
          i18n: 'Link.ChannelContract',
          url: '/channels/details/:id/contract/:contract',
          param: true,
        },
        {
          i18n: 'Link.ChannelContractRateDetails',
          url: '/channels/details/:id/contract/:contract/rate/:rate',
          param: true,
        },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNEL,
    },
  },
  {
    path: 'channels/rate_cards/:id',
    name: constants.routes.CHANNEL_RATE_CARD_DETAILS,
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/RateCardDetails.vue'),
    meta: {
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNEL,
    },
  },
  {
    path: 'channels/rate_cards/:id/pricing/',
    name: constants.routes.CHANNEL_RATE_CARD_PRICING,
    component: () =>
      import(/* webpackChunkName: "channel" */ '@/views/channels/RateCardPricing.vue'),
    meta: {
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.CHANNEL,
    },
  },
  {
    path: 'channels/rate/edit',
    name: constants.routes.CHANNEL_RATE_CARD_EDIT,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/channels/RateCardEdit.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.RATE_CARD,
    },
  },
  {
    path: 'channels/rate/create',
    name: constants.routes.CHANNEL_RATE_CARD_CREATE,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/channels/RateCardCreate.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.RATE_CARD_ADD,
    },
  },
  {
    path: 'channels/rate/view/:id',
    name: constants.routes.CHANNEL_RATE_CARD_VIEW,
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/channels/RateCardView.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.Channels', url: '/channels' },
      ],
      feature: constants.features.ROUTING_CHANNEL_MAIN,
      role: [constants.roles.RC_CONNECTIVITY_MANAGEMENT, constants.roles.ORG_MGMT],
      allowedAnyRole: [
        constants.roles.RC_TICKETING_VIEW,
        constants.roles.RC_TICKETING_EDIT,
      ],
      title: constants.titles.RATE_CARD,
    },
  },

  /* REPORTS */

  {
    path: 'reports/redemptions',
    name: constants.routes.REPORT_REDEMPTIONS,
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/WebRedemptionReport.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.RedemptionReport', url: '/reports/redemptions' },
      ],
      feature: constants.features.ROUTING_REDEMPTION_OP,
      role: [constants.roles.SUPPLIER_REPORTING_REDEMPTIONS_STAFF],
      title: constants.titles.REPORTS,
    },
  },
  {
    path: 'reports/arrivals',
    name: constants.routes.REPORT_ARRIVALS,
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/WebArrivalsReport.vue'),
    meta: {
      breadcrumb: [
        { i18n: 'Link.Home', url: '/' },
        { i18n: 'Link.ArrivalsReport', url: '/reports/redemptions' },
      ],
      feature: constants.features.ROUTING_ARRIVALS_OP,
      role: [constants.roles.SUPPLIER_REPORTING_STAFF],
      title: constants.titles.REPORTS,
    },
  },
  {
    path: 'reports/manifest',
    name: constants.routes.REPORT_CUSTOMER_MANIFEST,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ '@/views/reports/CustomerManifestReport.vue'
      ),
    meta: {
      feature: constants.features.ROUTING_REPORT_MAIN,
      role: [constants.roles.SUPPLIER_REPORTING_CUSTOMER_MANIFEST_STAFF],
      title: constants.titles.CUSTOMER_MANIFEST,
    },
  },
  {
    path: 'reports/all-bookings',
    name: 'report-all-bookings',
    component: () =>
      import(/* webpackChunkName: "reports" */ '@/views/reports/AllBookingsReport.vue'),
    meta: {
      feature: constants.features.ROUTING_REPORT_MAIN,
      role: [constants.roles.SUPPLIER_REPORTING_ALL_BOOKING_STAFF],
      title: constants.titles.ALL_BOOKINGS,
    },
  },

  /* ADMIN */

  {
    path: 'admin',
    name: constants.routes.ADMIN_APP_MNGMT,
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AppAdmin.vue'),
    meta: {
      role: [
        constants.roles.RC_CONNECTIVITY_MANAGEMENT,
        constants.roles.REDEAM_TECHNICAL_STAFF,
      ],
      title: constants.titles.ADMIN,
    },
  },
  {
    path: 'admin/company',
    name: constants.routes.ADMIN_APP_MNGMT_COMPANY,
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/AppAdminCompany.vue'),
    meta: {
      role: [
        constants.roles.RC_CONNECTIVITY_MANAGEMENT,
        constants.roles.REDEAM_TECHNICAL_STAFF,
      ],
      title: constants.titles.ADMIN,
    },
  },
];

if (!config.IsProductionEnv) {
  privateRoutes.push(...privateRoutesWIP);
}

// =============================================================================
// PUBLIC(auth not required) ROUTES FOR FullPage LAYOUT
// =============================================================================

const publicRoutesWIP: RouteConfig[] = [
  {
    path: 'register',
    name: constants.routes.REGISTER,
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/public/Registration.vue'),
  },
  {
    path: 'requested',
    name: constants.routes.ACCOUNT_REQUESTED,
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/public/AccountRequested.vue'),
  },
];

const publicRoutes: RouteConfig[] = [
  {
    path: 'login',
    name: constants.routes.LOGIN,
    component: () => import(/* webpackChunkName: "full" */ '@/views/public/Login.vue'),
  },
  {
    path: 'not-found',
    name: constants.routes.NOT_FOUND,
    component: () =>
      import(/* webpackChunkName: "full" */ '@/views/public/ErrorNotFound.vue'),
  },
  {
    path: 'forgot-password',
    name: constants.routes.FORGOT_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/public/ForgotPassword.vue'
      ),
  },
  {
    path: 'reset-password',
    name: constants.routes.RESET_PASSWORD,
    component: () =>
      import(/* webpackChunkName: "reset-password" */ '@/views/public/ResetPassword.vue'),
  },
];

if (!config.IsProductionEnv) {
  publicRoutes.push(...publicRoutesWIP);
}

// =============================================================================
// TOP LEVEL LAYOUT ROUTES
// =============================================================================

const layoutRoutes: RouteConfig[] = [
  {
    path: '',
    component: () => import(/* webpackChunkName: "main" */ '@/layouts/Main.vue'),
    meta: {
      requiresAuth: true, // see router.ts
    },
    children: privateRoutes,
  },
  {
    path: '',
    component: () => import(/* webpackChunkName: "full" */ '@/layouts/FullPage.vue'),
    children: publicRoutes,
  },
];

// =============================================================================
// ALL ROUTES
// =============================================================================

export const routes: RouteConfig[] = [
  ...layoutRoutes,
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: constants.routes.NOT_FOUND },
  },
  {
    path: '*',
    redirect: { name: constants.routes.NOT_FOUND }, // see in publicRoutes
  },
];
